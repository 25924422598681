import React, { useState } from "react";
import PopUpForm from "../PopUpForm/PopUpForm";

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const footerMenu1 = [
    {
      url: "/services/accounting-solution",
      menu: "Accounting Solution",
    },
    {
      url: "/services/recruitment-staffing",
      menu: "Recruitment: Staffing",
    },
    {
      url: "/services/payroll-solution",
      menu: "Payroll Solution",
    },
  ];

  const footerMenu2 = [
    {
      url: "/company/about-us",
      menu: "About Us",
    },
    {
      url: "/contact-us",
      menu: "Contact Us",
    },
    {
      url: "/company/testimonials",
      menu: "Testimonials",
    },
  ];
  return (
    <div>
      <footer class="site-footer">
        <div class="upper-footer">
          <div class="container">
            <div class="row">
              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 d-flex">
                <div class="footer-widget my-auto">
                  <a href="index.html">
                    <img
                      className="shieldit-logo"
                      src={require("../../Asserts/images/logo-1-1.png")}
                      alt="Awesome Image"
                    />
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 d-flex">
                <div class="footer-widget links-widget my-auto links-widget-one">
                  <div class="title-box">
                    <h3>Services</h3>
                  </div>
                  {footerMenu1.map((footerMenu1, index) => (
                    <ul class="link-lists">
                      <li>
                        <a href={footerMenu1.url}>{footerMenu1.menu}</a>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              <div class="col-lg-2 col-md-6 col-sm-12 col-xs-12 d-flex">
                <div class="footer-widget links-widget my-auto">
                  <div class="title-box">
                    <h3>Abouts Shield It</h3>
                  </div>
                  {footerMenu2.map((footerMenu2, index) => (
                    <ul class="link-lists">
                      <li>
                        <a href={footerMenu2.url}>{footerMenu2.menu}</a>
                      </li>
                    </ul>
                  ))}
                </div>
              </div>

              <div class="col-lg-5 col-md-6 col-sm-12 col-xs-12 d-flex">
                <div class="footer-widget my-auto">
                  <div class="btn-box">
                    <button onClick={togglePopup} class="thm-btn thm-btn-new">
                      Request Meeting
                    </button>
                    <PopUpForm show={showPopup} handleClose={togglePopup} />
                    <span class="btn-tag-line">
                      Try a better way <i class="payonline-icon-share"></i>
                    </span>
                  </div>
                  <div class="social">
                    <a href="https://www.instagram.com/shield_it_india?igsh=NGQ3ZndzaDV2Ym5y&utm_source=qr">
                      <i class="fa fa-instagram"></i>{" "}
                      <span className="icon-social">Instagram</span>
                    </a>
                    {/* <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a> */}
                    <a href="https://www.linkedin.com/company/101352722">
                      <i class="fa fa-linkedin"></i>{" "}
                      <span className="icon-social">linkedin</span>
                    </a>
                    {/* <a href="#">
                      <i class="fa fa-youtube-play"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* bottom footer start  */}
        <div class="bottom-footer">
          <div class="container">
            <p>
              &copy; Copyright 2024 by{" "}
              <a href="#" target="_blank">
                SHIELD IT
              </a>
            </p>
          </div>
        </div>
        {/* bottom footer end */}
      </footer>
    </div>
  );
};

export default Footer;
