import React from "react";

const WhatsAppChats = () => {
  return (
    <>
      {/* Chat box start */}
      <section>
        <div class="chat-box">
          <div class="">
            <a href="https://wa.link/dfaytl">
              <img
                width="70"
                height="70"
                src={require("../../Asserts/images/1085dc90-302f-4206-9f3f-2fe9807eb245.png")}
                class=""
                alt="shield it chat box"
              />{" "}
            </a>
          </div>
        </div>
      </section>
      {/* Chat box end */}
    </>
  );
};

export default WhatsAppChats;
