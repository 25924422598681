import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const Testimonials = () => {
  const bannerImages = [
    {
      url: `${require("../../../Asserts/images/testi1-1.jpg")}`,
      icon: <i className="payonline-icon-left-quotes-sign"></i>,
      para: (
        <>
          Partnering with Shield IT has significantly improved our
          cybersecurity. Their team's expertise and proactive approach have been
          invaluable.
        </>
      ),
      title: <h3>- Mohit Dhand</h3>,
      desination: "- Cofounder - Thinkitive Global Consulting Pvt Ltd",
    },
    {
      url: `${require("../../../Asserts/images/testi1-2.jpg")}`,
      icon: <i className="payonline-icon-left-quotes-sign"></i>,
      para: (
        <>
          Shield IT's staffing services have greatly streamlined our operations,
          allowing us to focus on growth, efficiency, and achieving greater
          success.
        </>
      ),
      title: <h3>- Jai Motwani</h3>,
      desination: "- Partner: Agrawal Dhand Motwani & Company.",
    },
  ];

  return (
    <div>
      <div className="inner-banner text-center">
        <div className="container">
          <BreadCrumbs />
          <h1>Testimonials</h1>
        </div>
      </div>

      <section className="testimonials-style-three sec-pad testimonials-page">
        <div className="container">
          <div className="row">
            {bannerImages.map((img, idx) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12" key={idx}>
                <div className="single-testimonial-style-three hvr-sweep-to-bottom">
                  {img.icon}
                  <p>{img.para}</p>
                  <div className="client-box testimonial-icon">
                    <img src={img.url} alt="Awesome Image" />
                    <div>
                      {img.title}{" "}
                      <h3 className="fonder-heading">{img.desination}</h3>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonials;
