import React, { useState } from "react";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const AccountingSolution = () => {
  const [activeTab, setActiveTab] = useState("effective");
  const [activedAccountingSolution, setActivedAccountingSolution] =
    useState("management");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  const handleAccountingSolution = (tabName) => {
    setActivedAccountingSolution(tabName);
  };
  return (
    <div>
      <div class="inner-banner text-center">
        <div class="container">
          <BreadCrumbs />

          <h1>Accounting Solution</h1>
        </div>
      </div>

      <section class="video-box-style-four sec-pad solutions-details-page">
        <div class="container-fluid">
          <div class="row no-gutters">
            <div class="col-lg-6">
              <div class="video-box-content">
                <div class="sec-title">
                  <span>Accounting benefits</span>
                  <h2>
                    Reliable Accounting Support For Continuous Business Success
                  </h2>
                </div>

                <p>
                  Our reliable accounting support ensures your business's
                  financial health and success. We offer ongoing assistance,
                  from routine financial management to complex accounting
                  challenges, providing accurate and timely solutions. Our
                  dedicated team is always available to address your needs,
                  ensuring compliance and efficiency. With regular updates and
                  proactive monitoring, we keep your accounting processes
                  running smoothly. Trust Shield IT for dependable support that
                  helps your business thrive and achieve sustained growth.
                </p>
              </div>
            </div>

            <div class="col-lg-6 d-flex">
              <div class="video-box my-auto">
                <img
                  src={require("../../../Asserts/images/video-3-1.jpg")}
                  alt="Awesome Image"
                />
                <div class="box">
                  <a href="#" class="video-popups">
                    <span className="playbtn">
                      <i class="fa-solid fa-play "></i>
                    </span>
                    <span class="btn-tag-line">
                      Learn about shield IT <i class="payonline-icon-share"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="time-tab-style-one sec-pad gray-bg">
        <div class="container">
          <div class="sec-title text-center">
            <span>Accounting Solution</span>
            <h2>
              Accounting Solution That <br />
              Gets Results
            </h2>
          </div>

          <ul class="nav nav-tabs tab-title clearfix" role="tablist">
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "effective" ? "active" : ""
                }`}
                href="#online"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("effective")}
              >
                <h3>Effective</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "accurate" ? "active" : ""
                }`}
                href="#time"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("accurate")}
              >
                <h3>Accurate</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "accounting" ? "active" : ""
                }`}
                href="#invision"
                role="tab"
                data-toggle="tab"
                onClick={() => handleTabClick("accounting")}
              >
                <h3> Results-Driven Accounting</h3>
              </a>
            </li>
          </ul>

          {/* effective */}
          {activeTab === "effective" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-1.png")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, our accounting solutions are designed to be
                      highly effective, providing you with accurate financial
                      management and insightful reporting. We ensure compliance
                      with all regulations, streamline your financial processes,
                      and deliver results that help your business grow. Our team
                      of experienced professionals is dedicated to maintaining
                      the highest standards of accuracy and efficiency, allowing
                      you to focus on your core business activities. Whether
                      it's managing daily transactions, preparing financial
                      statements, or ensuring timely tax filings, we handle it
                      all with precision. Trust Shield IT for dependable,
                      efficient accounting services that support your business's
                      success and growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Accurate */}
          {activeTab === "accurate" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-2.jpg")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, accuracy is at the core of our accounting
                      solutions. We pride ourselves on delivering precise
                      financial management, ensuring every detail is
                      meticulously handled. Our expert team utilizes advanced
                      tools and technologies to maintain the highest standards
                      of accuracy in all financial transactions, reports, and
                      tax filings. We understand the critical importance of
                      reliable financial data for making informed business
                      decisions. With Shield IT, you can trust that your
                      financial information is accurate, up-to-date, and
                      compliant with all regulations. Our commitment to accuracy
                      helps your business avoid costly errors and enhances your
                      financial stability and growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Accounting  */}
          {activeTab === "accounting" && (
            <div class="single-tab-content">
              <div class="row">
                <div class="col-xl-5 col-lg-12">
                  <div class="img-box">
                    <img
                      src={require("../../../Asserts/images/tab-1-3.jpg")}
                      alt="Awesome Image"
                    />
                  </div>
                </div>

                <div class="col-xl-7 col-lg-12">
                  <div class="text-box">
                    <p>
                      At Shield IT, our results-driven accounting services are
                      focused on delivering tangible benefits to your business.
                      We go beyond basic number-crunching, offering strategic
                      financial insights that help you make informed decisions
                      and drive growth. Our experienced team works diligently to
                      ensure your financial records are accurate and compliant,
                      providing you with the confidence to focus on your core
                      business activities. By leveraging advanced accounting
                      tools and methodologies, we streamline your financial
                      processes, reduce inefficiencies, and uncover
                      opportunities for cost savings. Trust Shield IT to provide
                      results-driven accounting solutions that enhance your
                      business's financial health and support its long-term
                      success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section class="sec-pad solutions-tab-one">
        <div class="container">
          <div class="sec-title text-center">
            <span>What We Do</span>
            <h2>
              Complete Accounting Solution <br />
              Management In One Application
            </h2>
          </div>

          <ul class="nav nav-tabs tab-title clearfix" role="tablist">
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "management" ? "active" : ""
                }`}
                href="#times"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("management")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-timer-1"></i>
                </span>
                <h3>Centralized Management</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "transactions" ? "active" : ""
                }`}
                href="#exchange"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("transactions")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-event"></i>
                </span>
                <h3>Automated Transactions</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "insights" ? "active" : ""
                }`}
                href="#time-off"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("insights")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-disable-alarm"></i>
                </span>
                <h3>Real-Time Insights</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "assurance" ? "active" : ""
                }`}
                href="#web-time"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("assurance")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-time-management"></i>
                </span>
                <h3>Compliance Assurance</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "handling" ? "active" : ""
                }`}
                href="#allocation"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("handling")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-employee"></i>
                </span>
                <h3>Secure Handling</h3>
              </a>
            </li>
            <li class="nav-item">
              <a
                className={`nav-link ${
                  activedAccountingSolution === "support" ? "active" : ""
                }`}
                href="#report"
                role="tab"
                data-toggle="tab"
                onClick={() => handleAccountingSolution("support")}
              >
                <span class="icon-box">
                  <i class="payonline-icon-report"></i>
                </span>
                <h3>Ongoing Support</h3>
              </a>
            </li>
          </ul>

          {activedAccountingSolution === "management" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Centralized Management</span>
                    <h2>
                      Centralized Management For Streamlined Financial Processes
                    </h2>
                  </div>

                  <p>
                    At Shield IT, we understand the complexities of managing
                    multiple financial tasks across various platforms. Our
                    centralized management approach simplifies this by
                    integrating all your financial processes into one cohesive
                    system. This streamlined method not only enhances efficiency
                    but also significantly reduces the risk of errors.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "transactions" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Automated Transactions</span>
                    <h2>Automate Transactions for Efficiency and Accuracy</h2>
                  </div>

                  <p>
                    At Shield IT, we recognize the importance of accuracy and
                    efficiency in financial management. Our automated
                    transaction solutions are designed to streamline your
                    financial processes, eliminating manual errors and saving
                    you valuable time. By automating routine tasks such as
                    invoicing, payroll, and expense tracking, we ensure that
                    your financial data is always precise and up-to-date.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "insights" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Real-Time Insights</span>
                    <h2>Gain Real-Time Financial Insights for Decisions</h2>
                  </div>

                  <p>
                    At Shield IT, we empower businesses with real-time financial
                    insights, providing the critical data needed for informed
                    decision-making. Our advanced financial management solutions
                    offer instant access to up-to-date financial information,
                    ensuring that you are always aware of your business’s
                    financial health.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "assurance" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Compliance Assurance</span>
                    <h2>
                      Ensure Compliance with Automated Assurance Solutions
                    </h2>
                  </div>

                  <p>
                    At Shield IT, we understand the critical importance of
                    compliance in financial management. Our automated assurance
                    solutions are designed to help your business stay compliant
                    with ever-changing regulations, reducing the risk of costly
                    fines and legal issues. By automating compliance tasks, we
                    ensure that all your financial processes adhere to federal,
                    state, and local laws.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "handling" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Secure Handling</span>
                    <h2>Protect Data with Secure Handling Solutions</h2>
                  </div>

                  <p>
                    At Shield IT, we prioritize the security of your financial
                    data with our secure handling solutions. In today's digital
                    landscape, safeguarding sensitive information is paramount
                    to preventing data breaches and maintaining your business's
                    integrity. Our comprehensive security measures are designed
                    to protect your data from unauthorized access, ensuring
                    confidentiality and peace of mind.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
          {activedAccountingSolution === "support" && (
            <div class="single-tab-content-two">
              <div class="row">
                <div class="col-lg-7">
                  <div class="sec-title">
                    <span>Ongoing Support</span>
                    <h2>Continuous Support for Your Business Success</h2>
                  </div>

                  <p>
                    At Shield IT, we believe that exceptional service doesn’t
                    end after implementation. Our commitment to your business
                    success includes continuous support to ensure your financial
                    operations run smoothly and efficiently. Our team of
                    dedicated professionals is always available to assist you
                    with any issues or questions that may arise, providing
                    timely and effective solutions.
                  </p>

                  <a href="/contact-us" class="thm-btn">
                    Contact Us
                  </a>
                </div>

                <div class="col-lg-5">
                  <img
                    src={require("../../../Asserts/images/mockup-1-8.png")}
                    alt="Awesome Image"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* Contact Us Section Start  */}
      <section class="cta-one sec-pad ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-7">
              <div class="cta-one-content">
                <div class="sec-title">
                  <span>Get in Touch</span>
                  <h2>
                    Talk With Our Experts <br />
                    Today
                  </h2>
                </div>
                <form action="#" class="cta-form">
                  <select class="selectpicker">
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                  </select>
                  <div class="btn-box">
                    <button type="submit" class="thm-btn">
                      Contact Us
                    </button>
                    <div class="btn-tag-line">
                      <i class="payonline-icon-share"></i>Are you ready get?
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 col-md-5 text-right">
              <img
                src={require("../../../Asserts/images/mockup-1-2.png")}
                alt="Awesome Image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Section end  */}
    </div>
  );
};

export default AccountingSolution;
