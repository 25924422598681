import React, { useState } from "react";
import ControlledCarousel from "../../Component/ControlledCarousel/ControlledCarousel";
import Brand from "../../Component/Brand/Brand";
import AllServices from "../../Component/AllServiceCard/AllServices";
import BreadCrumbs from "../../Component/BreadCrumbs/BreadCrumbs";
import BlogsComponent from "../../Component/BlogsComponent/BlogsComponent";

const Home = () => {
  return (
    <div className="homeSection-container">
      <ControlledCarousel />

      {/* Services Start */}
      <AllServices />
      {/* Services End */}

      {/* Hr solution Start */}
      <section className="solution-style-one sec-pad">
        <div className="container">
          <div className="sec-title text-center">
            <span>Solutions by Business Size</span>
            <h2>
              Recruitment Staffing Solutions That Grow With <br /> Your Business
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="single-solution-style-one hvr-bounce-to-bottom">
                <div className="img-box">
                  <img
                    src={require("../../Asserts/images/solution-1-1.png")}
                    alt="Awesome Image"
                  />
                </div>
                <div className="text-box">
                  <h2>Understand Your Business Needs</h2>
                  <h6>
                    We start by getting to know your business’s unique
                    challenges and goals. This initial step ensures that our HR
                    solutions are tailored to meet your specific requirements.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="single-solution-style-one hvr-bounce-to-bottom">
                <div className="img-box">
                  <img
                    src={require("../../Asserts/images/solution-1-2.png")}
                    alt="Awesome Image"
                  />
                </div>
                <div className="text-box">
                  <h2>Customized HR Strategy</h2>
                  <h6>
                    Based on our understanding of your needs, we create a
                    customized HR strategy that aligns with your business goals
                    and supports your growth journey.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="single-solution-style-one hvr-bounce-to-bottom">
                <div className="img-box">
                  <img
                    src={require("../../Asserts/images/solution-1-3.png")}
                    alt="Awesome Image"
                  />
                </div>
                <div className="text-box">
                  <h2>Scalable HR Solutions</h2>
                  <h6>
                    We implement flexible and scalable HR systems designed to
                    grow with your business. Our solutions are adaptable and can
                    evolve as your company’s needs change.
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="single-solution-style-one hvr-bounce-to-bottom">
                <div className="img-box">
                  <img
                    src={require("../../Asserts/images/solution-1-4.png")}
                    alt="Awesome Image"
                  />
                </div>
                <div className="text-box">
                  <h2>Support and Optimization</h2>
                  <h6>
                    We offer ongoing support and optimization to ensure your HR
                    solutions remain effective. Regular reviews and updates keep
                    our solutions aligned with your needs.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Hr solution end */}

      {/* Steps Section Start */}
      <section className="easy-steps-style-one sec-pad">
        <div className="container">
          <div className="sec-title text-center light">
            <span>how shield it works</span>
            <h2>
              Begin Your Journey With These Three Easy <br /> Steps
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="single-easy-step-one">
                <i className="payonline-icon-chat"></i>
                <h3>
                  <a href="#">Request A Meeting</a>
                </h3>
                <p>
                  Let's Understand Your Business and Address Your Specific
                  Workforce Challenges for Optimal Solutions
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="single-easy-step-one">
                <i className="payonline-icon-appointment"></i>
                <h3>
                  <a href="#">Receive A Custom Plan</a>
                </h3>
                <p>
                  Tailored Strategies to Address Your Business Needs and
                  Optimize Workforce Management for Success
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="single-easy-step-one">
                <i className="payonline-icon-handshake"></i>
                <h3>
                  <a href="#">Let’s Make It Happen</a>
                </h3>
                <p>
                  Implement Your Custom Plan and Drive Your Business Towards
                  Success with Our Expertise
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Steps Section end */}

      {/* Brand Section start */}
      {/* <Brand /> */}
      {/* Brand Section end */}

      {/* Video Section Start  */}
      <section className="sec-pad">
        <div className="container">
          <div className="row masonary-layout">
            <div className="col-xl-6 col-lg-12 masonary-item video-box">
              <div className="video-box-style-one">
                <div className="img-box">
                  <img
                    src={require("../../Asserts/images/video-1-1.jpg")}
                    alt="Awesome Image"
                  />
                  <div className="box">
                    <a
                      href="https://www.youtube.com/watch?v=W5BXPql9sfQ"
                      className="video-popup"
                    >
                      <i className="fa fa-play "></i>
                      <span className="btn-tag-line">
                        Learn about shield IT{" "}
                        <i className="payonline-icon-share"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 masonary-item expertize-box">
              <div className="expertize-box-one">
                <div className="inner-box">
                  <h3>
                    3+ <br /> Years
                  </h3>
                  <p>Industry Experience</p>
                  <a href="#">Work with an expert</a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 masonary-item video-box">
              <div className="video-box-two">
                <img
                  src={require("../../Asserts/images/video-1-2.jpg")}
                  alt="Awesome Image"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 masonary-item subscribe-form-box">
              <form action="#" className="subscribe-form">
                <h3>Stay Ahead With The Monthly Newsletter</h3>
                <input type="text" name="email" placeholder="Email address" />
                <button type="submit" className="thm-btn">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* Video Section Start  */}

      {/* Blog Section Start */}
      {/* <section className="sec-pad blog-style-one sec-pad">
        <div className="container">
          <div className="sec-title text-center">
            <span>Blog posts</span>
            <h2>
              Payonline latest articles <br /> for you
            </h2>
          </div>
          <div className="homeBlogSection">
            <BlogsComponent count={3} showPagination={false} />
          </div>
        </div>
      </section> */}
      {/* Blog Section end */}

      {/* Contact Us Section Start  */}
      <section className="cta-one sec-pad">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-7">
              <div className="cta-one-content">
                <div className="sec-title">
                  <span>Find Your Solution</span>
                  <h2>
                    Speak With A Shield IT <br /> Representative Today!
                  </h2>
                </div>

                <div className="btn-box">
                  <a href="/contact-us" className="thm-btn">
                    Let’s get started
                  </a>
                  <div className="btn-tag-line">
                    <i className="payonline-icon-share"></i>Are you ready?
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-5 text-right">
              <img
                src={require("../../Asserts/images/mockup-1-2.png")}
                alt="Awesome Image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Section end  */}
    </div>
  );
};

export default Home;
