import "./App.css";

import React from "react";

import Header from "./Component/Header/Header";
import Footer from "./Component/Footer/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Page/Home/Home";
import ContactUs from "./Page/ContactUs/ContactUs";
import Blogs from "./Page/Blog/Blogs";
import BlogsDetailsSidebar from "./Page/Blog/BlogsDetailsSidebar";
import AboutUs from "./Page/Company/AboutUs/AboutUs";
import Testimonials from "./Page/Company/Testimonials/Testimonials";
import OurTeam from "./Page/Company/OurTeam/OurTeam";
import AccountingSolution from "./Page/Services/AccountingSolution/AccountingSolution";
import PayrollSolution from "./Page/Services/PayrollSolution/PayrollSolution";
import HrSolution from "./Page/Services/HrSolution/HrSolution";
import Career from "./Page/Career/Career";
import TestForm from "./Page/TestForm";
import WhatsAppChats from "./Component/WhatsAppChats/WhatsAppChats";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <WhatsAppChats />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route
          path="/blogs/blogs-details-sidebar"
          element={<BlogsDetailsSidebar />}
        />
        <Route path="/company/about-us" element={<AboutUs />} />
        <Route path="/company/testimonials" element={<Testimonials />} />
        <Route path="/company/our-team" element={<OurTeam />} />
        <Route
          path="/services/accounting-solution"
          element={<AccountingSolution />}
        />
        <Route
          path="/services/payroll-solution"
          element={<PayrollSolution />}
        />
        <Route path="/services/recruitment-staffing" element={<HrSolution />} />
        <Route path="/career" element={<Career />} />
        <Route path="/test-form" element={<TestForm />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
