import React, { useState } from "react";
import imgMockup from "../../../Asserts/images/mockup-1-8.png";
import BreadCrumbs from "../../../Component/BreadCrumbs/BreadCrumbs";

const TabContent = ({ title, description, imgSrc }) => (
  <div className="single-tab-content">
    <div className="row">
      <div className="col-xl-5 col-lg-12">
        <div className="img-box">
          <img src={imgSrc} alt="Awesome Image" />
        </div>
      </div>
      <div className="col-xl-7 col-lg-12">
        <div className="text-box">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
);

const TabContent1 = ({ title, heading, description, imgSrc }) => (
  <div className="single-tab-content-two">
    <div className="row">
      <div className="col-lg-7">
        <div className="sec-title">
          <span>{title}</span>
          <h2>{heading}</h2>
        </div>
        {description.map((text, index) => (
          <p key={index}>{text}</p>
        ))}
        <a href="/contact-us" className="thm-btn">
          Contact Us
        </a>
      </div>
      <div className="col-lg-5">
        <img src={imgSrc} alt="Awesome Image" />
      </div>
    </div>
  </div>
);

const HrSolution = () => {
  const [activeTab, setActiveTab] = useState("effective");

  const [activedAccountingSolution, setActivedAccountingSolution] =
    useState("management");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleAccountingSolution = (tabName) => {
    setActivedAccountingSolution(tabName);
  };

  const tabData = {
    effective: {
      title: "Needs Assessment",
      description:
        "At Shield IT, our Staffing  begin with a thorough needs assessment. We take the time to understand your unique business challenges and workforce requirements through detailed consultations and data analysis. This step involves evaluating your current Staffing  processes, identifying gaps, and determining the specific needs of your organization. By gathering this crucial information, we can tailor our Staffing  strategies to align perfectly with your business goals. Our comprehensive needs assessment ensures that we provide targeted, effective solutions that enhance your Staffing  operations, improve employee satisfaction, and drive overall business success. Trust Shield IT for a precise and insightful needs assessment.",
      imgSrc: `${require("../../../Asserts/images/tab-1-1.png")}`,
    },
    accurate: {
      title: "Custom Implementation",
      description:
        "At Shield IT, we specialize in custom implementation of Staffing  tailored to your specific business needs. Following a thorough needs assessment, we design and deploy personalized Staffing  strategies and systems that address your unique challenges and objectives. This phase includes the development of efficient recruitment processes, streamlined onboarding programs, robust performance management systems, and comprehensive compliance frameworks. Our expert team ensures seamless integration with your existing operations, minimizing disruption and maximizing effectiveness. By customizing our implementation approach, we help you achieve a more productive, engaged, and compliant workforce, setting the stage for sustained business growth and success.",
      imgSrc: `${require("../../../Asserts/images/tab-1-2.jpg")}`,
    },
    accounting: {
      title: "Continuous Support",
      description:
        "At Shield IT, our commitment to your success extends beyond implementation with our continuous support services. We provide ongoing assistance to ensure your Staffing  systems operate smoothly and effectively. Our team is always available to address any issues, offer guidance, and make necessary adjustments to your Staffing  processes. Regular check-ins and performance reviews help us identify areas for improvement and keep your strategies aligned with evolving business goals. Additionally, we stay updated on the latest industry regulations and best practices to ensure your compliance. With Shield IT's continuous support, you can focus on your core business while we take care of your Staffing  needs.",
      imgSrc: `${require("../../../Asserts/images/tab-1-3.jpg")}`,
    },
  };
  const tabData1 = {
    management: {
      title: "Based on Talent",
      heading: "Outsourcing Based on Talent",
      description: [
        "Talent and skill are what set a candidate apart for a particular role, and we at Shield It prioritize them. We aim to assist businesses in finding the ideal candidate by using our well-proven screening process to determine the key development areas for either seasoned professionals or recent graduates. Providing a setting where both the organization and the candidate benefit in the end.",
      ],
      imgSrc: imgMockup,
      icon: <i class="payonline-icon-timer-1"></i>,
    },
    transactions: {
      title: "Specific Knowledge",
      heading: "Sector-specific Knowledge",
      description: [
        "All industries need temporary staffing companies because there is always a need for temporary employees. Because we are aware that every sector has different skill requirements, we create teams with industry-specific experience. These elements assist us in identifying the most suitable candidate for a firm based on its demands and the industry to which they belong.",
      ],
      imgSrc: imgMockup,
      icon: <i class="payonline-icon-event"></i>,
    },
    insights: {
      title: " Domestic  recruitments",
      heading: "Domestic  Recruitments",
      description: [
        "Because of our extensive domestic presence, we have become experts in providing temporary staffing in numerous cities. Our attention to detail in selecting the most suitable person, one you can rely on due to their qualifications and industry experience, sets us apart in this regard.",
      ],
      imgSrc: imgMockup,
      icon: <i class="payonline-icon-disable-alarm"></i>,
    },
    assurance: {
      title: "Adaptable agreements",
      heading: "Adaptable agreements",
      description: [
        "Since the duration of temporary employment might vary, we personalize each solution to give clients flexibility based on their skill set requirements, time frame, and scope of work.",
        "One of the leading temporary staffing companies, shield it, makes sure that candidates meet requirements and advance within the company. Your workers shouldn't be bothered by the ups and downs in the industry. You may rely on us to find qualified temporary workers.",
      ],
      imgSrc: imgMockup,
      icon: <i class="payonline-icon-time-management"></i>,
    },
  };

  return (
    <div>
      <div class="inner-banner text-center">
        <div class="container">
          <BreadCrumbs />

          <h1> Recruitment Staffing</h1>
        </div>
      </div>
      <section class="video-box-style-four sec-pad solutions-details-page">
        <div class="container-fluid">
          <div class="row no-gutters">
            <div class="col-lg-6">
              <div class="video-box-content">
                <div class="sec-title">
                  <span>INTRODUCTION</span>
                  <h2>Contract Staffing for Shield IT</h2>
                </div>

                <p>
                  The characteristics and requirements of the workplace have
                  evolved significantly from the employment market a few years
                  ago. Companies frequently require a trained labor force to
                  handle the ups and downs in the demands of their business.
                  Temporary staffing has shown to be a superior alternative in
                  numerous similar circumstances.
                </p>
                <p>
                  When an organization has tasks that need more assistance but
                  don't require long-term commitments, they may think about
                  using temporary staffing. This keeps the business lean and
                  facilitates the management of labor compliance while enabling
                  it to respond quickly to market demands. Additionally, it's
                  well recognized that hiring temporary workers boosts morale at
                  work and frequently results in significant hires.
                </p>
              </div>
            </div>

            <div class="col-lg-6 d-flex">
              <div class="video-box my-auto">
                <img
                  src={require("../../../Asserts/images/video-3-1.jpg")}
                  alt="Awesome Image"
                />
                <div class="box">
                  <a href="#" class="video-popups">
                    <span className="playbtn">
                      <i class="fa-solid fa-play "></i>
                    </span>
                    <span class="btn-tag-line">
                      Learn About Shield IT <i class="payonline-icon-share"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="time-tab-style-one sec-pad gray-bg">
        <div className="container">
          <div className="sec-title text-center">
            <span>Recruitment Staffing</span>
            <h2>Staffing is Deliver Outstanding Results</h2>
          </div>
          <ul className="nav nav-tabs tab-title clearfix" role="tablist">
            {Object.keys(tabData).map((key) => (
              <li className="nav-item" key={key}>
                <a
                  className={`nav-link ${activeTab === key ? "active" : ""}`}
                  href={`#${key}`}
                  role="tab"
                  data-toggle="tab"
                  onClick={() => handleTabClick(key)}
                >
                  <h3>{tabData[key].title}</h3>
                </a>
              </li>
            ))}
          </ul>

          {Object.keys(tabData).map(
            (key) =>
              activeTab === key && <TabContent key={key} {...tabData[key]} />
          )}
        </div>
      </section>

      <section className="sec-pad solutions-tab-one">
        <div className="container">
          <div className="sec-title text-center">
            <span>What We Do</span>
            <h2>
              Complete Accounting Solution <br />
              management in one application
            </h2>
          </div>
          <ul className="nav nav-tabs tab-title clearfix" role="tablist">
            {Object.keys(tabData1).map((key) => (
              <li className="nav-item" key={key}>
                <a
                  className={`nav-link ${
                    activedAccountingSolution === key ? "active" : ""
                  }`}
                  href={`#${key}`}
                  role="tab"
                  data-toggle="tab"
                  onClick={() => handleAccountingSolution(key)}
                >
                  <span className="icon-box">{tabData1[key].icon}</span>
                  <h3>{tabData1[key].title}</h3>
                </a>
              </li>
            ))}
          </ul>

          {Object.keys(tabData1).map(
            (key) =>
              activedAccountingSolution === key && (
                <TabContent1 key={key} {...tabData1[key]} />
              )
          )}
        </div>
      </section>
      {/* Contact Us Section Start  */}
      <section class="cta-one sec-pad ">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-7">
              <div class="cta-one-content">
                <div class="sec-title">
                  <span>Get in Touch</span>
                  <h2>
                    Talk With Our Experts <br />
                    Today
                  </h2>
                </div>
                <form action="#" class="cta-form">
                  <select class="selectpicker">
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                    <option>Payroll services</option>
                  </select>
                  <div class="btn-box">
                    <button type="submit" class="thm-btn">
                      Contact Us
                    </button>
                    <div class="btn-tag-line">
                      <i class="payonline-icon-share"></i>Are you ready get?
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 col-md-5 text-right">
              <img
                src={require("../../../Asserts/images/mockup-1-2.png")}
                alt="Awesome Image"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Contact Us Section end  */}
    </div>
  );
};

export default HrSolution;
