import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import PopUpForm from "../PopUpForm/PopUpForm";

// Dummy data for the carousel
const bannerImages = [
  {
    url: `${require("../../Asserts/images/banner3.jpg")}`, // Replace with actual path
    title: (
      <>
        Payroll
        <br />
        Solutions
      </>
    ),
    description: "Efficient payroll solutions for businesses.",
  },
  {
    url: `${require("../../Asserts/images/banner3.jpg")}`, // Replace with actual path
    title: (
      <>
        Accounting <br />
        Solutions
      </>
    ),
    description: "Reliable accounting solutions for accuracy.",
  },
  {
    url: `${require("../../Asserts/images/banner3.jpg")}`, // Replace with actual path
    title: (
      <>
        Recruitment <br />
        Services
      </>
    ),
    description: "Expert staffing solutions for businesses.",
  },
];

function ControlledCarousel() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      activeIndex={index}
      onSelect={handleSelect}
      className="slider-content-style slider-home-one "
    >
      {bannerImages.map((img, idx) => (
        <Carousel.Item
          className="carousel-inner"
          key={idx}
          style={{
            backgroundImage: `url(${img.url})`,
            height: "600px",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="">
            <div class="carousel-caption">
              <div class="container">
                <div class="box valign-middle">
                  <div class="content text-left">
                    <h3 data-animation="animated fadeInUp">{img.title}</h3>
                    <p>{img.description}</p>

                    <a onClick={togglePopup} class="thm-btn">
                      Request Meeting
                    </a>
                    <PopUpForm show={showPopup} handleClose={togglePopup} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ControlledCarousel;
