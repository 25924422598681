import React, { useState } from "react";

const AllServices = () => {
  const [activeTab, setActiveTab] = useState("payroll"); // Default to 'payroll'

  const handleTabClick = (tabName) => {
    console.log("Setting active tab to:", tabName); // Debugging state updates
    setActiveTab(tabName);
  };

  console.log("Current active tab:", activeTab); // Debugging current state
  return (
    <div>
      {/* Services Start */}
      <section className="service-style-one sec-pad-top">
        <div className="container">
          <div className="sec-title text-center">
            <span>Transforming Visions into Reality</span>
            <h2>All-in-one solution to your business</h2>
          </div>
          <ul className="nav nav-tabs tab-title clearfix" role="tablist">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "payroll" ? "active" : ""
                }`}
                onClick={() => handleTabClick("payroll")}
              >
                <span className="icon-box">
                  <i className="payonline-icon-dollar"></i>
                </span>
                <h3>Payroll Solution</h3>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "hrsolution" ? "active" : ""
                }`}
                onClick={() => handleTabClick("hrsolution")}
              >
                <span className="icon-box">
                  <i className="payonline-icon-clock"></i>
                </span>
                <h3>Recruitment Staffing</h3>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeTab === "accountingsolution" ? "active" : ""
                }`}
                onClick={() => handleTabClick("accountingsolution")}
              >
                <span className="icon-box">
                  <i className="payonline-icon-checkmark-outlined-circular-button"></i>
                </span>
                <h3>Accounting Solution</h3>
              </a>
            </li>
          </ul>

          {activeTab === "payroll" && (
            <div className="single-tab-content">
              <div className="icon-box">
                <i className="payonline-icon-dollar"></i>
              </div>
              <div className="text-box">
                <h3>Payroll</h3>
                <p>
                  Our efficient payroll solutions ensure accurate and timely
                  payments, compliance with regulations, and simplify payroll
                  management, freeing up your time to focus on your business.
                </p>
                <a href="/services/payroll-solution" className="read-more">
                  Read More
                </a>
              </div>
            </div>
          )}

          {activeTab === "hrsolution" && (
            <div className="single-tab-content">
              <div className="icon-box">
                <i className="payonline-icon-clock"></i>
              </div>
              <div className="text-box">
                <h3>Recruitment Staffing</h3>
                <p>
                  Our comprehensive Staffing streamline hiring, onboarding, and
                  employee management, ensuring compliance and boosting
                  productivity, so you can focus on growing your business
                  effectively.
                </p>
                <a href="/services/recruitment-staffing" className="read-more">
                  Read More
                </a>
              </div>
            </div>
          )}

          {activeTab === "accountingsolution" && (
            <div className="single-tab-content">
              <div className="icon-box">
                <i className="payonline-icon-checkmark-outlined-circular-button"></i>
              </div>
              <div className="text-box">
                <h3>Accounting Solution</h3>
                <p>
                  Our reliable accounting solutions provide accurate financial
                  management, ensure compliance, and offer insightful reporting,
                  helping you make informed decisions and streamline your
                  business operations.
                </p>
                <a href="/services/accounting-solution" className="read-more">
                  Read More
                </a>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* Services End */}
    </div>
  );
};

export default AllServices;
