import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
// import "./App.css"; // Make sure to create and import your CSS file for styling

const ConfirmationModal = ({ show, handleClose, handleConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <p>Are you sure you want to send this email?</p>
        <div className="modal-buttons">
          <button className="cancel-button" onClick={handleClose}>
            Cancel
          </button>
          <button className="ok-button" onClick={handleConfirm}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const SuccessModal = ({ show, handleClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="close" onClick={handleClose}>
          &times;
        </span>
        <p>Email sent successfully!</p>
        <div className="modal-buttons">
          <button className="ok-button" onClick={handleClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const PopUpForm = ({ show, handleClose }) => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true); // Show the confirmation modal before sending email
  };

  const handleConfirm = () => {
    setShowConfirmation(false); // Close the confirmation modal

    // Send email via EmailJS
    emailjs
      .sendForm(
        "service_59mygim",
        "template_4pmu0pm",
        form.current,
        "1APIgSiYMwbuckOPE"
      )
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccess(true); // Show the success modal
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email.");
        }
      );
  };

  return (
    <>
      <div className={`popup ${show ? "show" : ""}`}>
        <div className="popup-content">
          <span className="close" onClick={handleClose}>
            <i className="fa-solid fa-xmark"></i>
          </span>

          <span className="iconPopup">
            <i className="payonline-icon-handshake"></i>
          </span>
          <h2 className="popuptitle">Let's Connect and Collaborate!</h2>

          <form ref={form} className="formPopup" onSubmit={handleSubmit}>
            <label>
              <input
                placeholder="Enter Full Name"
                type="text"
                id="name"
                name="name"
                required
                onChange={handleInputChange}
              />
            </label>
            <label>
              <input
                placeholder="Enter Your Number"
                type="number"
                id="phone"
                name="phone"
                required
                onChange={handleInputChange}
              />
            </label>
            <label>
              <input
                placeholder="Enter Your Email Id"
                type="email"
                id="email"
                name="email"
                required
                onChange={handleInputChange}
              />
            </label>
            <button className="popbtn" type="submit">
              Request Meeting
            </button>
          </form>
        </div>
      </div>
      <ConfirmationModal
        show={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleConfirm={handleConfirm}
      />
      <SuccessModal
        show={showSuccess}
        handleClose={() => setShowSuccess(false)}
      />
    </>
  );
};

export default PopUpForm;
